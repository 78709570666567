<template>
	<div class="animated fadeIn">
		<CTabs  variant="pills" :active-tab="active_tab" @update:activeTab="changeTab" >
			<CTab title="Modules"/>
			<CTab title="Create New Module" v-if="$store.getters['can']('create-module')"/>
		</CTabs>
		<hr>
		<div>
	        <router-view @activeTab="emitHomeTab($event)">

	        </router-view>
	    </div>
	</div>
</template>
<script>
export default {
    name: 'ModuleHome',
	data() {
		return {
			active_tab: this.$router.history.current.path.substring(this.$router.history.current.path.lastIndexOf('/') + 1) == 'create' ? 1 : 0,
		}
	},
	methods: {
		changeTab: function(item) {
			this.$router.push(!item ? `/${this.$store.getters.getOrganizationName}/administrator/module` : `/${this.$store.getters.getOrganizationName}/administrator/module/create`)
			this.active_tab = item
		},
		emitHomeTab: function (value) {
			this.active_tab = value
		}
	}
}
</script>